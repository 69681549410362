import { Form, Formik } from "formik";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IcoPhone } from "src/assets";
import IcoSpinner from "src/assets/IcoSpinner";
import { authApi, publicApi } from "src/main/api";
import { CheckOnboardResponse } from "src/main/api/auth/endpoints";
import { ImagePlaceholder, InputField } from "src/main/components";
import { phPhoneSchema, thPhoneSchema } from "src/main/components/validation/loginSchema";
import { useAsyncTask } from "src/main/hooks";
import useActions from "src/main/hooks/useActions";
import useTypedSelector from "src/main/hooks/useTypedSelector";
import auth from "src/main/store/auth";
import { DeployCountry } from "src/main/types";
import { getDeployEnv, handleApiResponse, logger } from "src/main/utils";

type Props = {};

interface checkVipType {
  phone: string;
}

interface QueryParams {
  phone: string;
  brand: string;
}

const PUBLIC_URL = process.env.PUBLIC_URL;

const Status: React.FC<Props> = () => {
  const { t } = useTranslation();
  const actions = useActions();
  const state = useTypedSelector((state) => state);
  const [initialValues] = useState({
    phone: state.auth.mobile ?? "",
  } as checkVipType);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentEnv = getDeployEnv();

  const [versionNumber, setVersionNumber] = useState<string | undefined>();

  const getVersionNumber = async () => {
    try {
      const response = await fetch(PUBLIC_URL + "/version.json");
      const { version } = await response.json();
      const versionNum = moment(parseInt(version + "000")).format("YYYYMMDDHHmmss");
      setVersionNumber(versionNum ?? "test");
    } catch (error) {
      logger._console.log(error);
    }
  };

  useEffect(() => {
    getVersionNumber();
    return () => {};
  }, []);

  const brandRes = publicApi.useGetBrandQuery(
    {
      id: currentEnv.brandId,
      name: currentEnv.brand,
    },
    { skip: !currentEnv.brand && !currentEnv.brandId },
  );
  const brand = useMemo(() => brandRes.data?.model, [brandRes]);

  const [runPhoneNumberCheck, loading] = useAsyncTask("check/phone/number");
  const [checkOnboardStatus] = authApi.useCheckOnboardMutation();

  const handleSubmit = useCallback(
    (props: QueryParams) => {
      runPhoneNumberCheck(async () => {
        const { phone } = props;
        const result = await checkOnboardStatus({ phone });

        const { data, error } = handleApiResponse<CheckOnboardResponse>(result);

        if (!!error) {
          logger._console.log(error);
          toast.error(
            t(error?.data?.error?.message ?? "Something went wrong", {
              lng: currentEnv.defaultLang,
            }),
          );
          return;
        }

        dispatch(auth.slice.actions.setMobile(phone));
        if (!!data?.member) {
          dispatch(auth.slice.actions.updateMemberOnboardStatus(data.member));
          if (data.member.phoneVerified) {
            navigate(`/mobile-login`);
          } else {
            navigate(`/mobile-setup-password`);
          }
        } else {
          navigate(`/vipstatus/signup`);
        }
      });
    },
    [runPhoneNumberCheck, checkOnboardStatus, dispatch, t, currentEnv.defaultLang, navigate],
  );

  useEffect(() => {
    actions.clearVipStatusData();
  }, [actions]);

  useEffect(() => {
    if (currentEnv.isMain) {
      navigate("/vipcheck");
    }
  }, [navigate, currentEnv]);

  return (
    <div
      className=""
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(/images/body-bg/bg_lg_wavy_desktop.jpeg)`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
        zIndex: 2,
      }}
    >
      <div
        className=""
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "24px 18px",
        }}
      >
        <div style={{ flex: 1 }}></div>
        <div className="z-10 mt-10 mb-5 text-center text-[28px] font-bold leading-6 text-white drop-shadow">
          {t("CheckVipStatus", {
            lng: currentEnv.defaultLang,
          })}
        </div>
        <div className="bg-lvl-1-card-gradient flex w-full max-w-[400px] flex-col gap-4 rounded-lg border-2 border-token-border [backdrop-filter:blur(5px)]">
          {!!versionNumber && (
            <div className="ml-4 mt-2 text-[10px] font-bold text-secondary text-white opacity-30">V{versionNumber}</div>
          )}
          <div
            className={`z-10 text-center text-[20px] font-bold leading-6 text-white mt-${
              !!versionNumber ? "0" : "4"
            } mb-2 drop-shadow`}
          >
            {t("Log In", { lng: currentEnv.defaultLang })} /{" "}
            {t("Register", {
              lng: currentEnv.defaultLang,
            })}
          </div>
          <div className="login-form mx-auto w-full px-4 pb-10">
            <Formik
              initialValues={initialValues}
              validationSchema={currentEnv.deployCountry === DeployCountry.PH ? phPhoneSchema : thPhoneSchema}
              enableReinitialize
              onSubmit={(values) => {
                handleSubmit({
                  phone: values.phone,
                  brand: currentEnv.brand ?? "wow99",
                });
              }}
            >
              {({ errors, touched, isValid, values, submitCount, handleChange }) => (
                <Form>
                  <div className="mb-2 items-center">
                    <div className="relative">
                      <InputField
                        language={currentEnv.defaultLang}
                        value={values.phone}
                        onChange={(e) => {
                          e.target.value = e.target.value.trim();
                          handleChange(e);
                        }}
                        name="phone"
                        inputMode="tel"
                        label="Enter Phone No."
                        error={!!errors.phone && !!touched.phone}
                        helperText={errors.phone}
                        startIcon={
                          <IcoPhone
                            width="19"
                            height="19"
                          />
                        }
                        endButton={
                          <button
                            type="submit"
                            className="absolute right-[2px] bottom-[6px] w-[72px] rounded bg-gradient-to-r from-orange-dark to-orange-light px-3 py-2 text-[14px] font-bold text-white focus:outline-none focus:ring-2 focus:ring-blue-300"
                          >
                            {state.isLoading || loading ? (
                              <IcoSpinner />
                            ) : (
                              t("Go!", {
                                lng: currentEnv.defaultLang,
                              })
                            )}
                          </button>
                        }
                      />
                    </div>
                  </div>
                  {/* {!isValid && submitCount > 0 && (<div className="text-center text-red-400">{t("Please fill in all fields", { ns: ['main', 'home'], lng: currentEnv.defaultLang })}</div>)} */}
                </Form>
              )}
              {/* </form> */}
            </Formik>
          </div>
        </div>

        <div className="flex w-full items-center justify-center">
          <div className={`relative mt-8 w-[300px]`}>
            <ImagePlaceholder
              path={
                currentEnv.deployCountry === DeployCountry.TH
                  ? "/images/body-bg/banner.png"
                  : "/images/body-bg/WP_ILV_VIP_PH.png"
              }
            />
          </div>
        </div>

        <div style={{ flex: 1 }}></div>

        {!!brand?.icon?.url && (
          <div
            className="flex w-full items-center justify-center"
            style={{ display: "flex", alignSelf: "flex-end" }}
          >
            <div className="w-[120px]">
              <ImagePlaceholder
                path={brand?.icon?.url}
                aspectRatio={1.2}
              />
            </div>
          </div>
        )}
      </div>

      <div
        className="page-footer h-[74px] bg-[url('../public/images/login/caishen-coinpile-lg.png')] lg:h-[158px] lg:bg-[url('../public/images/login/coinstack-repeatx.png')]"
        style={{
          width: "100vw",
          zIndex: 1,
          alignSelf: "flex-end",
        }}
      ></div>
    </div>
  );
};

export default Status;
