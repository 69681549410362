import { SOCIAL_MEDIA_TYPE } from "src/main/contants";
import {
  AppConfiguration,
  CountryCurrency,
  CountryEnvKey,
  CountryPhoneCode,
  DeployCountry,
  DeployEnv,
  Language,
} from "src/main/types";

const MAIN_DOMAINS: Record<DeployCountry, string> = {
  [DeployCountry.TH]: "ilovevip.com",
  [DeployCountry.PH]: "ilovevip.org",
};

// APPLICATION CONFIGURATION
const appConfig: AppConfiguration<DeployCountry, Language, DeployEnv> = {
  domains: [
    // TH DOMAINS
    // TH main domains
    {
      domain: MAIN_DOMAINS.th,
      country: DeployCountry.TH,
      isMain: true,
      env: DeployEnv.Production,
    },
    {
      domain: "ilvth.com",
      country: DeployCountry.TH,
      isMain: true,
      env: DeployEnv.Production,
    },
    {
      domain: "ilvth1.com",
      country: DeployCountry.TH,
      isMain: true,
      env: DeployEnv.Production,
    },
    {
      domain: "ilvth2.com",
      country: DeployCountry.TH,
      isMain: true,
      env: DeployEnv.Production,
    },
    {
      domain: "ilvth3.com",
      country: DeployCountry.TH,
      isMain: true,
      env: DeployEnv.Production,
    },
    // TH brand domains
    {
      domain: "kk69vip.com",
      brand: "kk69",
      brandId: "84826a6e-5e23-5f91-8dd9-92fa69592ad8",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "kiss39vip.com",
      brand: "kiss39",
      brandId: "827f6d69-dcc2-56ff-8469-18fc47ae2c3f",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "w268vip.com",
      brand: "w268",
      brandId: "0688d141-4ccc-5cd9-bd8c-1130ff8c2731",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "pop369vip.com",
      brand: "pop369",
      brandId: "5c3a6963-d1d1-5d5d-bce7-442717e2d719",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "mk69vip.com",
      brand: "mk69",
      brandId: "610731c5-e9fd-5c91-a63b-cec53a9fa558",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "wow69vip.com",
      brand: "wow69",
      brandId: "909ce83a-642c-51cf-878b-be9eb76af9e0",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "xo268vip.com",
      brand: "xo268",
      brandId: "ec1938b6-5ac4-51e1-87dc-1e5f42a913d4",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "wow99vip.com",
      brand: "wow99",
      brandId: "113197dd-b0a2-5c48-904d-2700907ef835",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "xo666vip.com",
      brand: "xo666",
      brandId: "f7f0be6f-8d52-56bf-8066-b973d7c0390a",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "dee99vip.com",
      brand: "dee99",
      brandId: "a4b1db77-c544-5059-ab16-693c02b05fe0",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "big666vip.com",
      brand: "big666",
      brandId: "0e70f4d3-08c7-5965-861a-d7e3b0c978cb",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "boom88vip.com",
      brand: "boom88",
      brandId: "713db649-4039-5979-9d0b-753cc85981da",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "good777vip.com",
      brand: "good777",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "moo555vip.com",
      brand: "moo555",
      brandId: "b4bbc4dc-2af5-5c19-ae61-be6a4eba3898",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "wint9vip.com",
      brand: "wint8",
      brandId: "7b84c6df-18bd-5ee0-a6e0-02f3cec1000a",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "xogovip.com",
      brand: "xogo",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "chaiyo268vip.com",
      brand: "chaiyo268",
      brandId: "c4a67273-a87e-523e-a659-05a5e823d26a",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "kbk99vip.com",
      brand: "kbk99",
      brandId: "9ab6a3fe-3ae7-535a-9a68-c99a119d0fcd",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "wow268vip.com",
      brand: "wow268",
      brandId: "1c7e62a9-3aa3-5beb-9061-2297c3508f2b",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "kbk99vipp.com",
      brand: "kbk99",
      brandId: "9ab6a3fe-3ae7-535a-9a68-c99a119d0fcd",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "moo555vipp.com",
      brand: "moo555",
      brandId: "b4bbc4dc-2af5-5c19-ae61-be6a4eba3898",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "chaiyo268vipp.com",
      brand: "chaiyo268",
      brandId: "c4a67273-a87e-523e-a659-05a5e823d26a",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "wow268vipp.com",
      brand: "wow268",
      brandId: "1c7e62a9-3aa3-5beb-9061-2297c3508f2b",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "big666vipp.com",
      brand: "big666",
      brandId: "0e70f4d3-08c7-5965-861a-d7e3b0c978cb",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "go989vvip.com",
      brand: "go989",
      brandId: "691b70ec-878e-56b0-bdc2-05552d372008",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "sss268vip.com",
      brand: "sss268",
      brandId: "8872ffe9-3fb0-592a-8e82-92e1f1ce8e68",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "big188vip.com",
      brand: "big188",
      brandId: "0137a301-6c19-500d-92ed-ce12ec1c933e",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "hk39vip.com",
      brand: "hk39",
      brandId: "04ef6c47-13a0-55eb-9171-2a7a70cd06de",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },

    // PH DOMAINS
    // PH main domains
    {
      domain: MAIN_DOMAINS.ph,
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      isMain: true,
    },
    {
      domain: "ilovevipph.com",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      isMain: true,
    },
    {
      domain: "ilvph.com",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      isMain: true,
    },
    {
      domain: "ilvph1.com",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      isMain: true,
    },
    {
      domain: "ilvph2.com",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      isMain: true,
    },
    {
      domain: "ilvph3.com",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      isMain: true,
    },
    // PH brand domains
    {
      domain: "winhqvip.com",
      brand: "winhq",
      brandId: "6e081076-fbe4-51cf-875d-d15c468e7871",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.ph,
    },
    {
      domain: "ezjilivip.com",
      brand: "ezjili",
      brandId: "61cff682-7212-5984-be3f-53c3e9b064ff",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.ph,
    },
    {
      domain: "xojilivip.com",
      brand: "xojili",
      brandId: "d0e64725-4fa3-5b91-86a2-a13db591e122",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.ph,
    },
    {
      domain: "wowjilivip.com",
      brand: "wowjili",
      brandId: "2547ac33-8d00-5e9f-b41a-204ae3fb08ec",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.ph,
    },
    {
      domain: "winhqvipp.com",
      brand: "winhq",
      brandId: "6e081076-fbe4-51cf-875d-d15c468e7871",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.ph,
    },
    {
      domain: "ezjilivipp.com",
      brand: "ezjili",
      brandId: "61cff682-7212-5984-be3f-53c3e9b064ff",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.ph,
    },
    {
      domain: "xojilivipp.com",
      brand: "xojili",
      brandId: "d0e64725-4fa3-5b91-86a2-a13db591e122",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.ph,
    },
    {
      domain: "wowjilivipp.com",
      brand: "wowjili",
      brandId: "2547ac33-8d00-5e9f-b41a-204ae3fb08ec",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.ph,
    },
    {
      domain: "ezjiliilvph.com",
      brand: "ezjili",
      brandId: "61cff682-7212-5984-be3f-53c3e9b064ff",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.ph,
    },
    {
      domain: "xojiliilvph.com",
      brand: "xojili",
      brandId: "d0e64725-4fa3-5b91-86a2-a13db591e122",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.ph,
    },
    {
      domain: "winhqilvph.com",
      brand: "winhq",
      brandId: "6e081076-fbe4-51cf-875d-d15c468e7871",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.ph,
    },
    // STAGING DOMAINS
    {
      domain: "staging-member.ilovevip.com",
      country: DeployCountry.TH,
      env: DeployEnv.Staging,
      isMain: true,
      // Override country configuration
      countryConfig: {
        language: Language.EN,
      },
    },
    {
      domain: "staging-xo.ilovevip.com",
      brand: "xo268",
      brandId: "0955816c-2988-5f85-b4d4-5ab426970efa",
      country: DeployCountry.TH,
      env: DeployEnv.Staging,
      mainUrl: "staging-member.ilovevip.com",
    },
    {
      domain: "staging-member-ph.ilovevip.com",
      country: DeployCountry.PH,
      env: DeployEnv.Staging,
      isMain: true,
      // Override country configuration
      countryConfig: {
        language: Language.EN,
      },
    },
    // LOCAL DOMAINS
    {
      domain: "localhost",
      country: (process.env.REACT_APP_COUNTRY_ISO as DeployCountry) ?? DeployCountry.TH,
      env: DeployEnv.Local,
      isMain: !process.env.REACT_APP_BRAND,
      brand: process.env.REACT_APP_BRAND,
      brandId: process.env.REACT_APP_BRAND_ID,
      mainUrl: process.env.REACT_APP_MAIN_URL || "http://localhost:3000/",
      // Override country configuration
      countryConfig: {
        language: Language.EN,
        currency: process.env.REACT_APP_CURRENCY as CountryCurrency,
      },
    },
  ],
  // Common configurations for all countries
  countries: {
    [DeployCountry.TH]: {
      accountId: process.env.REACT_APP_TH_ACCOUNT_ID || "",
      language: Language.TH,
      phoneCode: CountryPhoneCode.TH,
      envKey: CountryEnvKey.TH,
      isoCode: DeployCountry.TH,
      currency: CountryCurrency.TH,
      postback: {
        campaignId: "cc962385-69d0-401d-bf56-e6e78ec0879d",
        siteUrl: `https://${MAIN_DOMAINS.th}/`,
      },
      contactPlatforms: [
        { type: SOCIAL_MEDIA_TYPE.LINE, url: "https://line.me/R/ti/p/@645ojnqc", title: "Line", primary: true },
      ],
    },
    [DeployCountry.PH]: {
      accountId: process.env.REACT_APP_PH_ACCOUNT_ID || "",
      language: Language.EN,
      phoneCode: CountryPhoneCode.PH,
      envKey: CountryEnvKey.PH,
      isoCode: DeployCountry.PH,
      currency: CountryCurrency.PH,
      postback: {
        campaignId: "462f3166-4d7b-4a60-a0df-8531fb263c28",
        siteUrl: `https://${MAIN_DOMAINS.ph}/`,
      },
      contactPlatforms: [
        {
          type: SOCIAL_MEDIA_TYPE.Messenger,
          url: "https://m.me/200969406423049",
          title: "Messenger",
          primary: true,
        },
        {
          type: SOCIAL_MEDIA_TYPE.Telegram,
          url: "https://t.me/ILV_PH_bot",
          title: "Telegram",
        },
      ],
    },
  },
  server: {
    baseURL: process.env.REACT_APP_BASE_URL ?? "",
  },
};

export default appConfig;
