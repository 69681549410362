export interface Meta {
  count?: number;
  limit: number;
  offset: number;
  search?: string;
  [index: string]: string | number | undefined;
}

export interface Option {
  value: number | string;
  label: string;
}

export interface MessageValues {
  [key: string]: string | number;
}

export enum ResourceType {
  Image = "image",
  Video = "video",
}

export interface ContactType {
  type: string;
  url: string;
  title: string;
  primary?: boolean;
}

export interface AppConfiguration<TDeployCountry extends string, TDefaultLanguage, TDeployEnv> {
  domains: DomainConfiguration<TDeployCountry, TDefaultLanguage, TDeployEnv>[];
  countries: Record<TDeployCountry, CountryConfiguration<TDefaultLanguage>>;
  server: ServerConfiguration;
}

export interface ServerConfiguration {
  baseURL: string;
}

export interface PostbackConfiguration {
  campaignId: string;
  siteUrl: string;
}

export interface DomainConfiguration<TDeployCountry extends string, TDefaultLanguage, TDeployEnv> {
  domain: string;
  brand?: string;
  brandId?: string;
  country: TDeployCountry;
  isMain?: boolean;
  // mainUrl will be used to redirect to the main domain
  // In case mailUrl is empty, it will get random domain from main domain list
  mainUrl?: string;
  env: TDeployEnv;
  // countryConfig will be used to override the default country configuration
  countryConfig?: Partial<CountryConfiguration<TDefaultLanguage>>;
}

export interface CountryConfiguration<TDefaultLanguage> {
  accountId: string;
  language: TDefaultLanguage;
  contactPlatforms: ContactType[];
  phoneCode: number;
  isoCode: string;
  envKey: CountryEnvKey;
  currency: CountryCurrency;
  postback: PostbackConfiguration;
}

export interface DeployEnvConfiguration {
  envKey: CountryEnvKey;
  deployCountry: DeployCountry;
  domain: string | null;
  country_iso: string | null;
  deploymentType: DeployEnv | null;
  isMain: boolean | null;
  country_code: number | null;
  currency: string;
  defaultLang: string;
  brand: string | null;
  brandId: string | null;
  mainUrl: string | null;
  accountId: string;
  contactPlatforms: ContactType[];
  postback: PostbackConfiguration | null;
}

export enum CountryPhoneCode {
  TH = 66,
  PH = 63,
}

export enum DeployCountry {
  TH = "th",
  PH = "ph",
}

export enum Language {
  TH = "th",
  EN = "en",
}

export enum CountryEnvKey {
  TH = "TH",
  PH = "PH",
}

export enum CountryCurrency {
  TH = "THB",
  PH = "PHP",
}

export enum DeployEnv {
  Production = "production",
  Staging = "staging",
  Local = "local",
}
