import { appConfig } from "src/config";
import { CountryEnvKey, DeployCountry, DeployEnvConfiguration, Language } from "src/main/types";
import { checkWithOrWithoutWWW } from "src/main/utils/misc";

class DeployEnvChecker {
  private static instance: DeployEnvChecker;
  private newEnv: DeployEnvConfiguration;

  private constructor() {
    this.newEnv = {
      envKey: CountryEnvKey.TH,
      deployCountry: DeployCountry.TH,
      domain: null,
      country_iso: null,
      deploymentType: null,
      isMain: null,
      country_code: null,
      currency: "",
      defaultLang: Language.EN,
      brand: null,
      brandId: null,
      mainUrl: null,
      accountId: "",
      contactPlatforms: [],
      postback: null,
    };
  }

  public static getInstance(): DeployEnvChecker {
    if (!DeployEnvChecker.instance) {
      DeployEnvChecker.instance = new DeployEnvChecker();
    }
    return DeployEnvChecker.instance;
  }

  private buildDeployEnv() {
    const hostname = window.location.hostname || "";

    const domainConfig = appConfig.domains.find((domainConfig) => checkWithOrWithoutWWW(domainConfig.domain, hostname));

    if (domainConfig) {
      // Merge country config with domain config
      let countryConfig = { ...appConfig.countries[domainConfig.country], ...(domainConfig.countryConfig || {}) };

      // If the domain is brand domain but doesn't define mainUrl
      // Pick random mainUrl
      let mainUrl = domainConfig.mainUrl;
      if (!domainConfig.isMain && !!domainConfig.brand && !domainConfig.mainUrl) {
        // Get main domains in same country and env
        const mainDomains = appConfig.domains.filter((domain) => {
          return domain.isMain && domain.country === domainConfig.country && domain.env === domainConfig.env;
        });
        // Pick random from main domains
        if (mainDomains.length > 0) {
          mainUrl = mainDomains[Math.floor(Math.random() * mainDomains.length)].domain;
        }
      }

      this.newEnv = {
        domain: domainConfig.domain,
        deploymentType: domainConfig.env,
        isMain: domainConfig.isMain ?? false,
        brand: domainConfig.brand ?? null,
        brandId: domainConfig.brandId ?? null,
        deployCountry: domainConfig.country,
        mainUrl: !!mainUrl ? `https://${mainUrl}/` : null,
        envKey: countryConfig.envKey,
        defaultLang: countryConfig.language,
        country_iso: countryConfig.isoCode,
        country_code: countryConfig.phoneCode,
        currency: countryConfig.currency,
        accountId: countryConfig.accountId,
        contactPlatforms: countryConfig.contactPlatforms ?? [],
        postback: countryConfig.postback ?? null,
      };
    }
  }

  public getDeployEnv(): DeployEnvConfiguration {
    if (this.newEnv.domain === null) {
      this.buildDeployEnv();
    }
    return this.newEnv;
  }
}

const deployEnvChecker = DeployEnvChecker.getInstance();

export const getDeployEnv = deployEnvChecker.getDeployEnv.bind(deployEnvChecker);
